/* styles.css */
.bg-custom-image {
  position: relative;
  background-image: url('./opacitiy.png');
  background-size: cover;
  background-position: center;
}

.bg-custom-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* semi-transparent overlay */
  z-index: 1; /* ensures the overlay is above the image */
}

.text-content {
  position: relative;
  z-index: 2; /* ensures the text is above the overlay */
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}

.highlight {
  background-color: rgba(255, 154, 36, 1); /* yellow highlight */
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
}
.highlight2 {
  background-color: #0087f7; /* yellow highlight */
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
}
