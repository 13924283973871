/* Keyframes for the sliding animation */
@keyframes slideAnim {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Looper styles */
.looper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.looper__innerList {
  display: flex;
  justify-content: flex-start;
  width: max-content;
  animation: slideAnim 30s linear infinite;
}

.looper__listInstance {
  display: flex;
  width: max-content;
  padding-right: 10px;
}

.looper__listInstance > .relative {
  margin: 0 100px; /* Adjust the margin as per your design */
}

/* Example content styles */
:root {
  --green: #59C3C3;
  --offwhite: #EBEBEB;
  --red: #e65a5e;
}

html {
  height: 100%;
}

body {

  display: flex;
  flex-direction: column;
}

.description {
  margin-bottom: 16px;
  text-align: center;
}

.contentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin: 10px;
  padding: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
}

.contentBlock--one {
  width: 120px;

  font-weight: 600;
  font-size: 18px;
}

.contentBlock--one:last-of-type {
  color: var(--green);
  margin-right: 36px;
}

.contentBlock--two {
  color: var(--green);
  font-size: 48px;

}
