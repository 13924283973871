/* HorizontalScroll.css */

html {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  overflow-y: visible;
  position: relative;
  height: unset;
}

html, body {
  overflow-x: hidden;
  margin: 0;
}

.container {
  width: 400%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: yellow;
}

.lastContainer {
  display: flex;
  height: 100vh;
  background: yellow;
  justify-content: center;
  align-items: center;
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4em;
  flex: 1 0 100%;
  min-height: 100vh;
}

.blue {
  background-color: #5bc0de;
}

.red {
  background-color: #d9534f;
}

.orange {
  background-color: #f0ad4e;
}

.purple {
  background-color: #5e5e5e;
}

.description {
  color: #fff;
  text-align: center;
  font-size: 2em;
}

.scroll-down {
  margin-top: 20px;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
@font-face {
  font-family: 'GTEesti-Display';
  src: url('./assets/fonts/GT-Eesti-Display-Regular-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTEesti-Display';
  src: url('./assets/fonts/GT-Eesti-Display-Bold-Trial.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GTEesti-Display';
  src: url('./assets/fonts/GT-Eesti-Display-Medium-Italic-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

body {
  font-family: 'GTEesti-Display', sans-serif;
}

