/* HorizontalScroll.css */

/* Reset or Normalize Styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 400%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden; /* Ensure no horizontal scrollbar */
}

/* Tailwind CSS directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Specific Component Styles */
.scrollable-content {
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: 100vh; /* Adjust min-height as needed */
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: yellow;
}

/* Rest of your styles ... */
